import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import {MDBModalRef, MDBModalService, MdbTableDirective} from 'angular-bootstrap-md';
import { BackendTmpService, Member, SyncStats } from '../../services/backend-tmp.service'
import {EditUserComponent} from '../edit-user/edit-user.component';
import {ConfirmationComponent} from '../confirmation/confirmation.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-company-board',
  templateUrl: './company-board.component.html',
  styleUrls: ['./company-board.component.scss']
})
export class CompanyBoardComponent implements OnInit {
  @ViewChild(MdbTableDirective, {static: true}) mdbTable: MdbTableDirective|undefined = undefined;
  modalRef: MDBModalRef;

  searchText = '';
  previous: any = null;

  tableData: Member[] = [];
  private sorted = false;
  checkAll: boolean;

  constructor(private backendTmpService: BackendTmpService,
              private modalService: MDBModalService,
              private router: Router,
  ) { }

  @HostListener('input') oninput(): void {
    this.searchItems();
  }

  ngOnInit(): void {
    this.backendTmpService.getMembers().subscribe((members: Member[]) => {
      this.tableData = members;
      this.mdbTable.setDataSource(this.tableData);
      this.previous = this.mdbTable.getDataSource();
    });

    if (!this.mdbTable) {
      return;
    }
  }

  sortBy(by: string | any): void {

    this.tableData.sort((a: any, b: any) => {
      if (a[by] < b[by]) {
        return this.sorted ? 1 : -1;
      }
      if (a[by] > b[by]) {
        return this.sorted ? -1 : 1;
      }

      return 0;
    });

    this.sorted = !this.sorted;
  }

  searchItems(): void {
    if (!this.mdbTable) {
      return;
    }
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
        this.mdbTable.setDataSource(this.previous);
        this.tableData = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
        this.tableData = this.mdbTable.searchLocalDataBy(this.searchText);
        this.mdbTable.setDataSource(prev);
    }
  }

  selectAll(): void {
    this.tableData = this.tableData.map(data => Object({...data, selected: this.checkAll}));
    this.mdbTable.setDataSource(this.tableData);
  }

  editRow(): void {
    const row = this.tableData.filter(data => data.selected)[0];
    this.modalRef = this.modalService.show(EditUserComponent, {
      class: 'modal-dialog-centered',
      data: {
        user: row
      }
    });
  }

  setRowSubscribe(subscribed: boolean): void {
    const row = this.tableData.filter(data => data.selected)[0];
    this.modalService.show(ConfirmationComponent, {
      class: 'modal-sm modal-notify modal-danger',
      data: {
        // TODO do backend Call
        // TODO update list from api response
        callback: () => row.subscribed = subscribed
      }
    });

  }

  oneSelected(): boolean {
    return this.tableData.filter(data => data.selected).length === 1;
  }

  gotoRow(row: Member): void {
    this.router.navigate([`member/${row.uid}`]);
  }
}

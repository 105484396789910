import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  url = '/contact';
  constructor(private http: HttpClient) { }

  sendMessage(messageContent: any): Observable<string> {
    return this.http.post(this.url,
      JSON.stringify(messageContent),
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text'
      });
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CheckboxModule, WavesModule, ButtonsModule, MDBBootstrapModule, ModalModule, DropdownModule} from 'angular-bootstrap-md';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import {
  MsalInterceptor,
  MsalModule
} from '@azure/msal-angular';
import { FailedComponent } from './components/failed/failed.component';
import { IntroComponent } from './components/intro/intro.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { GenericLayoutComponent } from './components/generic-layout/generic-layout.component';
import { CompanyBoardComponent } from './components/company-board/company-board.component';
import { SyncStatusComponent } from './components/sync-status/sync-status.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { MemberInfoComponent } from './components/member-info/member-info.component';
import {QRCodeModule} from 'angular2-qrcode';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LayoutComponent,
    NavbarComponent,
    FooterComponent,
    FailedComponent,
    IntroComponent,
    ContactUsComponent,
    GenericLayoutComponent,
    CompanyBoardComponent,
    SyncStatusComponent,
    EditUserComponent,
    ConfirmationComponent,
    MemberInfoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    CheckboxModule,
    WavesModule,
    ButtonsModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    MsalModule.forRoot({
        auth: {
          clientId: 'c851171e-aa6d-4796-b7f8-c6f960f7af4b',
          // redirectUri: 'https://localhost:4200',
          redirectUri: 'https://wekards.com',
          // postLogoutRedirectUri: 'https://localhost:4200/backend/api/azure/logout'
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE, // set to true for IE 11
        },
      },
      {
        popUp: !isIE,
          consentScopes: [
            'user.read.all',
            'directory.read.all',
            'directory.read.all',
            'user.read.all',
          ],
        protectedResourceMap: [
          ['https://graph.microsoft.com/v1.0/me', [
            'user.read.all',
            'directory.read.all',
            'directory.read.all',
            'user.read.all',
          ]]
        ],
        extraQueryParameters: {}
      }),
    DropdownModule.forRoot(),
    QRCodeModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import {Component, HostListener, OnInit} from '@angular/core';
import {MDBModalRef, MDBModalService} from 'angular-bootstrap-md';
import {BackendTmpService, Member} from '../../services/backend-tmp.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmationComponent} from '../confirmation/confirmation.component';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  user: Member;
  editForm: FormGroup;
  public disabledSubmitButton = true;

  @HostListener('input') oninput(): void {
    if (this.editForm.valid) {
      this.disabledSubmitButton = false;
    }
  }

  constructor(fb: FormBuilder, public modalRef: MDBModalRef, public confirmModalRef: MDBModalRef, private modalService: MDBModalService) {
    this.editForm = fb.group({
      userJobControl: ['', Validators.required],
      userCompanyEmailControl: ['', Validators.compose([Validators.required, Validators.email])],
      userSecondaryEmailControl: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  ngOnInit(): void {
  }

  get userJobControl(): AbstractControl {
    return this.editForm.get('userJobControl');
  }

  get userCompanyEmailControl(): AbstractControl {
    return this.editForm.get('userCompanyEmailControl');
  }

  get userSecondaryEmailControl(): AbstractControl {
    return this.editForm.get('userSecondaryEmailControl');
  }

  saveUser(): void {
    this.confirmModalRef = this.modalService.show(ConfirmationComponent, {
      class: 'modal-sm modal-notify modal-danger',
      data: {
        callback: this.modalRef.hide
      }
    });
    // this.contactUsService.sendMessage(this.editForm.value).subscribe(() => {
    //   alert('Your message has been sent.');
    //   this.editForm.reset();
    //   this.disabledSubmitButton = true;
    // }, (error: any) => {
    //   console.log('Error', error);
    // });
  }
}

<div class="mt-3 mt-md-0 view intro-2">
  <div class="full-bg-img">
    <!--        <div class="mask rgba-purple-light flex-center">-->
    <div class="mask flex-center rgba-black-strong">
      <div class="container text-center white-text wow fadeInUp mt-5">
        <h2 class="tag-line display-5 strong"><strong>It is always <strong>nyce</strong> to meet you!</strong></h2>
        <h4 class="sub-tag-line"><strong>The identification platform for an employee's success</strong></h4>
<!--        <p class="d-none d-md-block"><strong>with creative, elegant, personalized products tailored to the need of our customers</strong></p>-->
        <a mdbBtn color="mdb-color" size="lg" class="waves-light mt-2 mt-md-5 z-depth-5 rounded" href="/contact-us" mdbWavesEffect>Contact us</a>
      </div>
    </div>
  </div>
</div>

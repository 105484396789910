import {Component, OnDestroy, OnInit} from '@angular/core';
import {BroadcastService, MsalService} from '@azure/msal-angular';
import {Subscription} from 'rxjs';
import {BackendService} from '../../services/backend.service';
// @ts-ignore
import {AuthResponse, CryptoUtils, Logger} from 'msal';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy  {
  subscriptions: Subscription[] = [];
  loggedIn = false;

  constructor(
    private authService: MsalService,
    private broadcastService: BroadcastService,
    private backendService: BackendService,
  ) {}

  ngOnInit(): void {
    let loginSuccessSubscription: Subscription;
    let loginFailureSubscription: Subscription;

    this.checkAccount();

    loginSuccessSubscription = this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.checkAccount();
    });

    loginFailureSubscription = this.broadcastService.subscribe('msal:loginFailure', (error) => {
      console.log('Login Fails:', error);
    });

    this.subscriptions.push(loginSuccessSubscription);
    this.subscriptions.push(loginFailureSubscription);

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError || !response) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }

      console.log('Redirect Success: ', response.accessToken);
    });

    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));
  }

  checkAccount(): void {
    const account = this.authService.getAccount();
    if (!account) {
      this.loggedIn = false;
      return;
    }
    this.authService.acquireTokenSilent({
      scopes: [
        'user.read.all',
        'directory.read.all',
        'directory.read.all',
        'user.read.all',
      ],
      account
    }).then((response: AuthResponse) => {
      this.backendService.login(response).subscribe(() => {
        this.loggedIn = true;
      });
    }).catch((error: any) => {
      console.log(`Failed aquiring token ${error}`);
    });
  }

  login(): void {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup();
    }
  }

  logout(): void {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}

<app-generic-layout>
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-lg-8">
        <mdb-card>

          <mdb-card-header class="stylish-color white-text text-center py-4">
            <h5>
              <strong>Contact us</strong>
            </h5>
          </mdb-card-header>

          <mdb-card-body class="px-lg-5 pt-0">

            <form
              class="text-center"
              style="color: #4e4e4e;"
              [formGroup]="contactForm"
              (ngSubmit)="onSubmit()">
              <div class="md-form mt-3">
                <input
                  type="text"
                  formControlName="contactFormName"
                  id="materialContactFormName"
                  class="form-control"
                  mdbInput
                  mdbValidate/>
                <label for="materialContactFormName">Name</label>
                <mdb-error *ngIf="name.invalid && (name.dirty || name.touched)">
                  Input invalid
                </mdb-error>
                <mdb-success *ngIf="name.valid && (name.dirty || name.touched)">
                  Input valid
                </mdb-success>
              </div>

              <div class="md-form">
                <input
                  type="email"
                  formControlName="contactFormEmail"
                  id="materialContactFormEmail"
                  class="form-control"
                  mdbInput
                  mdbValidate/>
                <label for="materialContactFormEmail">E-mail</label>
                <mdb-error *ngIf="email.invalid && (email.dirty || email.touched)">
                  Input invalid
                </mdb-error>
                <mdb-success *ngIf="email.valid && (email.dirty || email.touched)">
                  Input valid
                </mdb-success>
              </div>

              <div class="md-form">
                <input
                  type="text"
                  formControlName="contactFormCompanyName"
                  id="materialContactFormCompanyName"
                  class="form-control"
                  mdbInput
                  mdbValidate/>
                <label for="materialContactFormCompanyName">Company name</label>
                <mdb-error *ngIf="companyName.invalid && (companyName.dirty || companyName.touched)">
                  Input invalid
                </mdb-error>
                <mdb-success *ngIf="companyName.valid && (companyName.dirty || companyName.touched)">
                  Input valid
                </mdb-success>
              </div>

              <div class="md-form">
                <input
                  type="url"
                  formControlName="contactFormWebsite"
                  id="materialContactFormWebsite"
                  class="form-control"
                  mdbInput
                  mdbValidate/>
                <label for="materialContactFormWebsite">Company Website</label>
                <mdb-error *ngIf="website.invalid && (website.dirty || website.touched)">
                  Input invalid
                </mdb-error>
                <mdb-success *ngIf="website.valid && (website.dirty || website.touched)">
                  Input valid
                </mdb-success>
              </div>

              <div class="md-form">
                <textarea
                  type="text"
                  formControlName="contactFormMessage"
                  id="materialContactFormMessage"
                  class="form-control md-textarea"
                  mdbInput
                  mdbValidate></textarea>
                <label for="materialContactFormMessage">Message</label>
                <mdb-error *ngIf="message.invalid && (message.dirty || message.touched)">
                  Input invalid
                </mdb-error>
                <mdb-success *ngIf="message.valid && (message.dirty || message.touched)">
                  Input valid
                </mdb-success>
              </div>

              <div class="md-form" style="text-align: left">
                <mdb-checkbox
                  value="copy">Send me a copy of this message</mdb-checkbox>
              </div>

              <button
                mdbBtn
                color="amber"
                class="my-4 waves-effect btn btn-amber"
                mdbWavesEffect
                type="submit"
                [disabled]="disabledSubmitButton">
                <i far class="fa fa-paper-plane left"></i>
                Send
              </button>
            </form>


          </mdb-card-body>

        </mdb-card>
      </div>
    </div>
  </div>

</app-generic-layout>



<app-generic-layout>
  <app-sync-status class="container"></app-sync-status>
  <div class="container">
    <div class="row">
      <div class="col-md-12 mx-auto">
        <div class="md-form">
          <input
            type="text"
            [(ngModel)]="searchText"
            class="form-control"
            id="search"
            mdbInput
          />
          <label for="search">Search</label>
        </div>
      </div>
    </div>
  </div>
  <mdb-card>
    <!--Card image-->
    <div class="view view-cascade elegant-color narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">

      <div>
      </div>

      <a href="" class="white-text mx-3">Members</a>

      <div>
        <button type="button" mdbBtn color="green" size="sm" class="px-2" *ngIf="checkAll" mdbWavesEffect>
          <mdb-icon fas icon="user-check" class="mt-0"></mdb-icon> Subscribe All
        </button>
        <button type="button" mdbBtn color="red" size="sm" class="px-2" *ngIf="checkAll" mdbWavesEffect>
          <mdb-icon fas icon="user-times" class="mt-0"></mdb-icon> Unsubscribe All
        </button>
        <button mdbBtn color="green" size="sm" mdbWavesEffect type="button" class="px-2"
                *ngIf="oneSelected()" (click)="setRowSubscribe(true)">
          <mdb-icon fas icon="user-check"></mdb-icon> Subscribe
        </button>
        <button mdbBtn color="danger" size="sm" mdbWavesEffect type="button" class="px-2"
                *ngIf="oneSelected()" (click)="setRowSubscribe(false)">
          <mdb-icon fas icon="user-times"></mdb-icon> Unsubscribe
        </button>
        <button mdbBtn color="primary" size="sm" mdbWavesEffect type="button" class="px-2"
                *ngIf="oneSelected()" (click)="editRow()">
          <mdb-icon fas icon="user-edit"></mdb-icon> Edit
        </button>
      </div>

    </div>
    <!--/Card image-->

    <div class="px-4">

      <div class="table-wrapper">
        <!--Table-->
        <table class="mb-0 table table-striped table-responsive-md" mdbTable #tableEl="mdbTable" hover="true">

          <!--Table head-->
          <thead>
          <tr>
            <th>
              <mdb-checkbox [(ngModel)]="checkAll" (change)="selectAll()"></mdb-checkbox>
            </th>
            <th class="th-lg">
              <a (click)="sortBy('subscribed')">Subscribed
                <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
              </a>
            </th>
            <th class="th-lg">
              <a (click)="sortBy('first_name')">First Name
                <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
              </a>
            </th>
            <th class="th-lg">
              <a (click)="sortBy('last_name')">Last Name
                <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
              </a>
            </th>
            <th class="th-lg">
              <a (click)="sortBy('username')">Role
                <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
              </a>
            </th>
            <th class="th-lg">
              <a (click)="sortBy('email')">Email
                <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
              </a>
            </th>
          </tr>
          </thead>
          <!--Table head-->

          <!--Table body-->
          <tbody>
          <tr *ngFor="let row of tableData;let i = index" (click)="gotoRow(row)">
            <td>
              <mdb-checkbox [(ngModel)]="row.selected"></mdb-checkbox>
            </td>
            <td>
              <mdb-icon fas [icon]="row.subscribed ? 'check' : 'times'" [class]="row.subscribed ? 'green-text' : 'red-text'"></mdb-icon>
            </td>
            <td>{{row.first_name}}</td>
            <td>{{row.last_name}}</td>
            <td>{{row.job}}</td>
            <td>{{row.email}}</td>
          </tr>
          </tbody>
          <!--Table body-->
        </table>
        <!--Table-->
      </div>
    </div>
  </mdb-card>

</app-generic-layout>

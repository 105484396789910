<app-generic-layout>
  <div class="container">
    <a type="button" mdbBtn color="primary" href="/company-board" mdbWavesEffect>Back to Dashboard</a>

    <div class="row">
      <div class="col-md-6 align-items-center d-flex">
        <mdb-card class="container p-4">
          <div class="row">
            <div class="col">
              <div>
                <qr-code [value]="memberInfo.uid" [size]="150"></qr-code>
              </div>
            </div>
            <div class="col">
              <h3>{{ memberInfo.first_name }} {{ memberInfo.last_name }}</h3>
              <h3>Title: {{ memberInfo.job }}</h3>
              <h3>{{ memberInfo.email }}</h3>
            </div>
          </div>
        </mdb-card>
      </div>
      <div class="col-md-6">
        <h2 class="text-center">Contacts</h2>
        <mdb-card class="table-wrapper">
          <table mdbTable mdbTableScroll scrollY="true" maxHeight="600" striped="true" class="table table table-striped ">
            <thead class="elegant-color white-text">
            <tr>
              <th class="th-lg">First Name</th>
              <th class="th-lg">Last Name</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let contact of memberInfo.contacts; let i = index">
              <td>{{contact.first_name}}</td>
              <td>{{contact.last_name}}</td>
            </tr>
            </tbody>
          </table>
        </mdb-card>

      </div>
    </div>
  </div>
</app-generic-layout>


<!--<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"-->
<!--     aria-labelledby="myModalLabel" aria-hidden="true">-->
<!--  <div class="modal-dialog modal-sm modal-notify modal-danger" role="document">-->
<div class="modal-content text-center">
  <!--Header-->
  <div class="modal-header d-flex justify-content-center">
    <p class="heading">Are you sure?</p>
  </div>

  <!--Body-->
  <div class="modal-body">
<!--    <mdb-icon fas icon="times" size="4x" class="animated rotateIn"></mdb-icon>-->
  </div>

  <!--Footer-->
  <div class="modal-footer justify-content-center">
    <a type="button" mdbBtn color="danger" outline="true" class="waves-effect" mdbWavesEffect data-dismiss="modal" (click)="callCallbackAndHide()">Yes</a>
    <a type="button" mdbBtn color="danger" class="waves-effect" mdbWavesEffect data-dismiss="modal" (click)="modalRef.hide()">No</a>
  </div>
</div>

import {Component, HostListener, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ContactUsService} from '../../services/contact-us.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  disabledSubmitButton = true;

  @HostListener('input') oninput(): void {
    if (this.contactForm.valid) {
      this.disabledSubmitButton = false;
    }
  }

  constructor(fb: FormBuilder, private contactUsService: ContactUsService) {
    this.contactForm = fb.group({
      contactFormName: ['', Validators.required],
      contactFormEmail: ['', Validators.compose([Validators.required, Validators.email])],
      contactFormWebsite: ['', Validators.compose([Validators.required, Validators.pattern(/.*\\..*\\..*/)])],
      contactFormCompanyName: ['', Validators.required],
      contactFormMessage: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  get name(): AbstractControl {
    return this.contactForm.get('contactFormName');
  }
  get email(): AbstractControl {
    return this.contactForm.get('contactFormEmail');
  }

  get companyName(): AbstractControl {
    return this.contactForm.get('contactFormCompanyName');
  }

  get website(): AbstractControl {
    return this.contactForm.get('contactFormWebsite');
  }


  get message(): AbstractControl {
    return this.contactForm.get('contactFormMessage');
  }

  onSubmit(): void {
    this.contactUsService.sendMessage(this.contactForm.value).subscribe(() => {
      alert('Your message has been sent.');
      this.contactForm.reset();
      this.disabledSubmitButton = true;
    }, (error: any) => {
      console.log('Error', error);
    });
  }

}

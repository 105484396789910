import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BackendTmpService, Member, MemberInfo} from '../../services/backend-tmp.service';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-member-info',
  templateUrl: './member-info.component.html',
  styleUrls: ['./member-info.component.scss']
})
export class MemberInfoComponent implements OnInit {
  memberInfo: MemberInfo;

  constructor(private backendTmpService: BackendTmpService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.backendTmpService.getMemberInfo(params.get('id')).subscribe((memberInfo: MemberInfo) => {
        this.memberInfo = memberInfo;
      });
    });
  }
}

<div class="layout">
    <header class="h-100">
      <app-navbar></app-navbar>
      <!-- Main -->
      <app-intro></app-intro>
      <!-- /.Main -->
    </header>
    <!--Main Navigation-->
  
    <!--Main Layout-->
    <main class="container">
      <div class="row  py-5">
        <ng-content></ng-content>
      </div>
    </main>
    <!--Main Layout-->
  
    <app-footer></app-footer>
  </div>
  
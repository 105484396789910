<app-layout title="Nyce">
  <!--Section: Features v.4-->
  <section>

    <!--Section heading-->
    <h2 class="h1 py-5 font-weight-bold text-center">Seamless end user integration</h2>
    <!--Section description-->
    <p class="px-5 mb-5 pb-3 lead grey-text text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
      sed do
      eiusmod tempor incididunt ut labore et dolore magna aliqua.
      Ut enim ad minim veniam.</p>

    <!--Grid row-->
    <div class="row">

      <!--Grid column-->
      <div class="col-md-4">

        <!--Grid row-->
        <div class="row mb-2">
          <div class="col-2">
            <mdb-icon fas icon="flag-checkered" size="2x" class="deep-purple-text"></mdb-icon>
          </div>
          <div class="col-10 text-left">
            <h5 class="font-weight-bold">International</h5>
            <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores
              nam,
              aperiam minima assumenda
              deleniti hic.</p>
          </div>
        </div>
        <!--Grid row-->

        <!--Grid row-->
        <div class="row mb-2">
          <div class="col-2">
            <mdb-icon fas icon="flask" size="2x" class="deep-purple-text"></mdb-icon>
          </div>
          <div class="col-10 text-left">
            <h5 class="font-weight-bold">Experimental</h5>
            <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores
              nam,
              aperiam minima assumenda
              deleniti hic.</p>
          </div>
        </div>
        <!--Grid row-->

        <!--Grid row-->
        <div class="row mb-2">
          <div class="col-2">
            <mdb-icon fas icon="glass-martini" size="2x" class="deep-purple-text"></mdb-icon>
          </div>
          <div class="col-10 text-left">
            <h5 class="font-weight-bold">Relaxing</h5>
            <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores
              nam,
              aperiam minima assumenda
              deleniti hic.</p>
          </div>
        </div>
        <!--Grid row-->

      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-md-4 mb-2 center-on-small-only flex-center">
        <img src="https://mdbootstrap.com/img/Mockups/Transparent/Small/iphone-portfolio1.png" alt="" class="z-depth-0">
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-md-4">

        <!--Grid row-->
        <div class="row mb-2">
          <div class="col-2">
            <mdb-icon fas icon="heart" size="2x" class="deep-purple-text"></mdb-icon>
          </div>
          <div class="col-10 text-left">
            <h5 class="font-weight-bold">Beloved</h5>
            <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores
              nam,
              aperiam minima assumenda
              deleniti hic.</p>
          </div>
        </div>
        <!--Grid row-->

        <!--Grid row-->
        <div class="row mb-2">
          <div class="col-2">
            <mdb-icon fas icon="bolt" size="2x" class="deep-purple-text"></mdb-icon>
          </div>
          <div class="col-10 text-left">
            <h5 class="font-weight-bold">Rapid</h5>
            <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores
              nam,
              aperiam minima assumenda
              deleniti hic.</p>
          </div>
        </div>
        <!--Grid row-->

        <!--Grid row-->
        <div class="row mb-2">
          <div class="col-2">
            <mdb-icon fas icon="magic" size="2x" class="deep-purple-text"></mdb-icon>
          </div>
          <div class="col-10 text-left">
            <h5 class="font-weight-bold">Magical</h5>
            <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores
              nam,
              aperiam minima assumenda
              deleniti hic.</p>
          </div>
        </div>
        <!--Grid row-->

      </div>
      <!--Grid column-->

    </div>
    <!--Grid row-->

  </section>
  <!--/Section: Features v.4-->
  <section class="team-section text-center my-5">

    <!-- Section heading -->
    <h2 class="h1-responsive font-weight-bold my-5">Our amazing team</h2>
    <!-- Section description -->
    <p class="grey-text w-responsive mx-auto mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
      Fugit, error
      amet numquam iure provident voluptate esse quasi,
      veritatis totam voluptas nostrum quisquam eum porro a pariatur veniam.</p>

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-lg-3 col-md-6 mb-lg-0 mb-5">
        <div class="avatar mx-auto">
          <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg" class="rounded-circle z-depth-1"
               alt="Sample avatar">
        </div>
        <h5 class="font-weight-bold mt-4 mb-3">Anna Williams</h5>
        <p class="text-uppercase blue-text">
          <strong>Graphic designer</strong>
        </p>
        <p class="grey-text">Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
          adipisci sed
          quia non numquam modi
          tempora eius.</p>
        <ul class="list-unstyled mb-0">
          <!-- Facebook -->
          <a class="p-2 fa-lg fb-ic">
            <mdb-icon fab icon="facebook-f" class="blue-text"> </mdb-icon>
          </a>
          <!-- Twitter -->
          <a class="p-2 fa-lg tw-ic">
            <mdb-icon fab icon="twitter" class="blue-text"> </mdb-icon>
          </a>
          <!-- Instagram -->
          <a class="p-2 fa-lg ins-ic">
            <mdb-icon fab icon="instagram" class="blue-text"> </mdb-icon>
          </a>
        </ul>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-lg-3 col-md-6 mb-lg-0 mb-5">
        <div class="avatar mx-auto">
          <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(3).jpg" class="rounded-circle z-depth-1"
               alt="Sample avatar">
        </div>
        <h5 class="font-weight-bold mt-4 mb-3">John Doe</h5>
        <p class="text-uppercase blue-text">
          <strong>Web developer</strong>
        </p>
        <p class="grey-text">Sed ut perspiciatis unde omnis iste natus error sit voluptatem ipsa accusantium
          doloremque
          rem laudantium totam
          aperiam.</p>
        <ul class="list-unstyled mb-0">
          <!-- Facebook -->
          <a class="p-2 fa-lg fb-ic">
            <mdb-icon fab icon="facebook-f" class="blue-text"> </mdb-icon>
          </a>
          <!-- Instagram -->
          <a class="p-2 fa-lg ins-ic">
            <mdb-icon fab icon="instagram" class="blue-text"> </mdb-icon>
          </a>
        </ul>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-lg-3 col-md-6 mb-md-0 mb-5">
        <div class="avatar mx-auto">
          <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(30).jpg" class="rounded-circle z-depth-1"
               alt="Sample avatar">
        </div>
        <h5 class="font-weight-bold mt-4 mb-3">Maria Smith</h5>
        <p class="text-uppercase blue-text">
          <strong>Photographer</strong>
        </p>
        <p class="grey-text">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit
          anim est fugiat nulla
          id eu laborum.</p>
        <ul class="list-unstyled mb-0">
          <a class="p-2 fa-lg fb-ic">
            <mdb-icon fab icon="facebook-f" class="blue-text"> </mdb-icon>
          </a>
          <!-- Twitter -->
          <a class="p-2 fa-lg ins-ic">
            <mdb-icon fab icon="dribbble" class="blue-text"> </mdb-icon>
          </a>
          <!-- Instagram -->
          <a class="p-2 fa-lg ins-ic">
            <mdb-icon fab icon="instagram" class="blue-text"> </mdb-icon>
          </a>
        </ul>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-lg-3 col-md-6">
        <div class="avatar mx-auto">
          <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(32).jpg" class="rounded-circle z-depth-1"
               alt="Sample avatar">
        </div>
        <h5 class="font-weight-bold mt-4 mb-3">Tom Adams</h5>
        <p class="text-uppercase blue-text">
          <strong>Backend developer</strong>
        </p>
        <p class="grey-text">Perspiciatis repellendus ad odit consequuntur, eveniet earum nisi qui consectetur
          totam
          officia voluptates perferendis
          voluptatibus aut.</p>
        <ul class="list-unstyled mb-0">
          <!-- Facebook -->
          <a class="p-2 fa-lg fb-ic">
            <mdb-icon fab icon="facebook-f" class="blue-text"> </mdb-icon>
          </a>
          <!-- Github -->
          <a class="p-2 fa-lg ins-ic">
            <mdb-icon fab icon="github" class="blue-text"> </mdb-icon>
          </a>
        </ul>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </section>

</app-layout>

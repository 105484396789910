<div class="jumbotron">
  <h2 class="display-5">Welcome Bishir!</h2>
  <p class="lead">You can use this portal to manage your members for Google.</p>
  <hr class="my-4">
  <p>In case the list was out of sync you can synchronize your members.</p>
  <button mdbBtn class="curvy-button" type="button" color="elegant" mdbWavesEffect (click)="startSync()" [disabled]="status !== undefined && status.sync_status === 'IN_PROGRESS'">
    <mdb-icon fas icon="sync-alt"></mdb-icon> Sync
  </button>
  <div *ngIf="status">Last synced started on {{status.started_at * 1000 | date}} and took {{status.duration}}s.</div>
  <div *ngIf="status">Next sync scheduled for {{status.next_run_time * 1000 | date}}</div>
</div>

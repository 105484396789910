import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, throwError, of, OperatorFunction, ObservableInput, timer} from 'rxjs';
import {catchError, delayWhen, retry, retryWhen, switchAll, tap} from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { EMPTY, Subject } from 'rxjs';

const RECONNECT_INTERVAL = 1000;
const WS_ENDPOINT = 'localhost:8080';

@Injectable({
  providedIn: 'root'
})
export class BackendTmpService {
  url = '/backend/api/azure';
  private socket: WebSocketSubject<SyncStats>;
  private messagesSubject = new Subject<ObservableInput<SyncStats>>();
  public messages = this.messagesSubject.pipe(
    switchAll<SyncStats>(),
    catchError(e => { throw e; })
  );

  constructor(private http: HttpClient) { }

  public connectWebsocket(config: { reconnect: boolean } = { reconnect: false }): void {
    if (!this.socket || this.socket.closed) {
      this.socket = this.getNewWebSocket();
      const messages = this.socket.pipe(
        config.reconnect ? this.reconnectWebsocket : o => o,
        tap({
          error: error => console.log(error),
        }), catchError(_ => EMPTY));
      this.messagesSubject.next(messages);
    }
  }

  private reconnectWebsocket(observable: Observable<any>): Observable<any> {
    return observable.pipe(
      retryWhen(errors => errors.pipe(
          tap(val => console.log('[Data Service] Try to reconnect', val)),
          delayWhen(_ => timer(RECONNECT_INTERVAL))
        )
      )
    );
  }

  private getNewWebSocket(): WebSocketSubject<SyncStats> {
    return webSocket({
      url: WS_ENDPOINT,
      closeObserver: {
        next: () => {
          console.log('[DataService]: connection closed');
          this.socket = undefined;
          this.connectWebsocket({reconnect: true});
        }
      },
    });
  }

  getMembers(): Observable<Member[]>{
    return this.http.get<Member[]>(`${this.url}/member`).pipe(
      catchError(err => this.fakeMembers())
    );
  }

  getMemberInfo(id: string): Observable<MemberInfo>{
    return this.http.get<MemberInfo>(`${this.url}/member_info/${id}` ).pipe(
      catchError(err => this.fakeMemberInfo(id))
    );
  }

  getSyncStatus(): Observable<SyncStats>{
    return this.http.get<SyncStats>(`${this.url}/sync`).pipe(
      catchError(err => this.fakeSync())
    );
  }

  startSync(): Observable<SyncStats>{
    return this.http.post<SyncStats>(`${this.url}/sync`, null).pipe(
      catchError(err => this.fakeSyncProgress())
    );
  }

  fakeSync(): Observable<SyncStats>{
    return of<SyncStats>(
        {
          tid: '1',
          sync_status: 'COMPLETED',
          started_at: 0,
          finished_at: 0,
          name: 'Name',
          duration: 0.0,
          next_run_time: 0,
        });
  }

  fakeSyncProgress(): Observable<SyncStats>{
    return of<SyncStats>(
        {
          tid: '1',
          sync_status: 'IN_PROGRESS',
          started_at: 0,
          finished_at: 0,
          name: 'Name',
          duration: 0.0,
          next_run_time: 0,
        });
  }

  fakeMembers(): Observable<Member[]>{
    return of<Member[]>([...Array(20)].map( e =>
      Object({
        tid: '1',
        uid: '3',
        first_name: 'Jean',
        last_name: 'Pierre',
        email: 'pierevans@gmail.com',
        job: 'Engineer',
        subscribed: Math.random() < 0.5,
        selected: false
      })));
  }

  private fakeMemberInfo(id: string): Observable<MemberInfo> {
    return of<MemberInfo>(
      {
        tid: '1',
        uid: '3',
        first_name: 'Jean',
        last_name: 'Pierre',
        email: 'pierevans@gmail.com',
        job: 'Engineer',
        subscribed: Math.random() < 0.5,
        contacts: [...Array(20)].map( e =>
          Object({
            qr: 'pierevans@gmail.com',
            first_name: 'Joe',
            last_name: 'Contact',
            email: 'pierevans@gmail.com',
          }))
      });
  }
}

export interface Member {
  tid: string;
  uid: string;
  first_name: string;
  last_name: string;
  email: string;
  job: string;
  subscribed: boolean;
  selected: boolean;
}

export interface MemberInfo {
  tid: string;
  uid: string;
  first_name: string;
  last_name: string;
  email: string;
  job: string;
  subscribed: boolean;
  contacts: {
    qr: string;
    first_name: string;
    last_name: string;
    email: string;
  }[];
}

export interface SyncStats {
  tid: string;
  name: string;
  duration: number;
  sync_status: string;
  started_at: number;
  finished_at: number;
  next_run_time: number;
}

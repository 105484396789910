<div class="modal-content">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">Edit {{ user.first_name }} {{ user.last_name }}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body mx-3">
    <div class="md-form mb-5">
      <mdb-icon fas icon="briefcase" class="prefix grey-text"></mdb-icon>
      <input type="text" id="form34" class="form-control" mdbInput mdbValidate
             [formControl]="userJobControl" [(ngModel)]="user.job">
      <label for="form34">Job</label>
      <mdb-error
        *ngIf="userJobControl.invalid && (userJobControl.dirty || userJobControl.touched)">Input
        invalid
      </mdb-error>
      <mdb-success
        *ngIf="userJobControl.valid && (userJobControl.dirty || userJobControl.touched)">Input
        valid
      </mdb-success>
    </div>

    <div class="md-form mb-5">
      <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
      <input type="email" id="form29" class="form-control" mdbInput mdbValidate
             [formControl]="userCompanyEmailControl" [(ngModel)]="user.email">
      <label for="form29">Company email</label>
      <mdb-error
        *ngIf="userCompanyEmailControl.invalid && (userCompanyEmailControl.dirty || userCompanyEmailControl.touched)">
        Input invalid
      </mdb-error>
      <mdb-success
        *ngIf="userCompanyEmailControl.valid && (userCompanyEmailControl.dirty || userCompanyEmailControl.touched)">Input
        valid
      </mdb-success>
    </div>

    <div class="md-form">
      <mdb-icon fas icon="envelope-open" class="prefix grey-text"></mdb-icon>
      <input type="email" id="form8" class=" form-control" mdbInput mdbValidate
                [formControl]="userSecondaryEmailControl"[(ngModel)]="user.email">
      <label for="form8">Secondary email</label>
      <mdb-error
        *ngIf="userSecondaryEmailControl.invalid && (userSecondaryEmailControl.dirty || userSecondaryEmailControl.touched)">
        Input invalid
      </mdb-error>
      <mdb-success
        *ngIf="userSecondaryEmailControl.valid && (userSecondaryEmailControl.dirty || userSecondaryEmailControl.touched)">
        Input valid
      </mdb-success>
    </div>

  </div>
  <div class="modal-footer d-flex justify-content-center">
    <button mdbBtn color="primary" class="waves-light" mdbWavesEffect [disabled]="disabledSubmitButton" (click)="saveUser()">
      Save
      <mdb-icon fas icon="save" class="ml-1"></mdb-icon>
    </button>
  </div>
</div>

<mdb-navbar SideClass="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar intro-fixed-nav" [containerInside]="false">
    <mdb-navbar-brand>
      <a class="logo navbar-brand" href="/">
        <img src="../../../assets/logo-fill.jpg" alt="teknik logo" width="50" style="border-radius: 5px">
      </a>
    </mdb-navbar-brand>
    <links>
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active waves-light" mdbWavesEffect>
          <a class="nav-link" href="/"><strong>Home</strong>
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item waves-light" mdbWavesEffect>
          <a class="nav-link" href="/contact-us"><strong>Contact Us</strong></a>
        </li>
      </ul>

      <ul class="navbar-nav ml-auto nav-flex-icons">
        <li class="nav-item waves-light" mdbWavesEffect>
          <a class="nav-link" *ngIf="!loggedIn" (click)="login()"><strong>Login</strong></a>
        </li>
        <li class="nav-item" *ngIf="loggedIn" >
          <a class="nav-link">
            <div class="btn-group" mdbDropdown>
              <mdb-icon fas icon="user" mdbDropdownToggle></mdb-icon>
              <div class="dropdown-menu dropdown-menu-right dropdown-primary">
                <a class="dropdown-item" href="/company-board">Company board</a>
                <div class="divider dropdown-divider"></div>
                <a class="dropdown-item" (click)="logout()"><strong>Logout</strong></a>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </links>
  </mdb-navbar>


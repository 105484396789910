import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import {FailedComponent} from './components/failed/failed.component';
import {ContactUsComponent} from './components/contact-us/contact-us.component';
import { CompanyBoardComponent } from './components/company-board/company-board.component';
import { MemberInfoComponent } from './components/member-info/member-info.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'member/:id',
    component: MemberInfoComponent,
  },
  {
    path: 'login-failed',
    component: FailedComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
  {
    path: 'company-board',
    component: CompanyBoardComponent,
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

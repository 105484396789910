<div class="layout">
  <header class="h-100">
    <app-navbar class="full-bar-color"></app-navbar>
  </header>
  <!--Main Navigation-->

  <!--Main Layout-->
  <main class="container">
    <div class="row  py-5">
      <ng-content></ng-content>
    </div>
  </main>
  <!--Main Layout-->

  <app-footer></app-footer>
</div>

import { Component, OnInit } from '@angular/core';
import {BackendTmpService, SyncStats} from '../../services/backend-tmp.service';
import {map, tap} from 'rxjs/operators';

@Component({
  selector: 'app-sync-status',
  templateUrl: './sync-status.component.html',
  styleUrls: ['./sync-status.component.scss']
})
export class SyncStatusComponent implements OnInit {
  status: SyncStats|undefined = undefined;

  constructor(private backendTmpService: BackendTmpService) {
    this.backendTmpService.connectWebsocket();
  }

  ngOnInit(): void {
    this.backendTmpService.getSyncStatus().subscribe((status: SyncStats) => this.status = status);
  }

  startSync(): void{
    this.backendTmpService.startSync().subscribe((status: SyncStats) => this.status = status);
    this.backendTmpService.messages.pipe(
      map((status) => this.status = status ),
      tap({
        error: (error) => console.log('[Live Component] Error: ', error),
        complete: () => console.log('[Live Component] Connection Closed ')
      })
    );
  }

}

<!-- Footer -->
<footer class="page-footer font-small unique-color-dark darken-3">

  <!-- Footer Elements -->
  <div class="container">

    <!-- Grid row-->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 py-5">
        <div class="mb-5 flex-center">

          <!-- Facebook -->
          <a class="fb-ic">
            <mdb-icon fab icon="facebook" size="2x" class="white-text mr-md-3 mr-3"></mdb-icon>
          </a>
          <!-- Twitter -->
          <a class="tw-ic">
            <mdb-icon fab icon="twitter" size="2x" class="white-text mr-md-3 mr-3"></mdb-icon>
          </a>
          <!-- Google +-->
          <a class="gplus-ic">
            <mdb-icon fab icon="google-plus" size="2x" class="white-text mr-md-3 mr-3"></mdb-icon>
          </a>
          <!--Linkedin -->
          <a class="li-ic">
            <mdb-icon fab icon="linkedin" size="2x" class="white-text mr-md-3 mr-3"></mdb-icon>
          </a>

        </div>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row-->

  </div>
  <!-- Footer Elements -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2021 Copyright:
    <a href="https://mdbootstrap.com/"> nyce.com</a>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->
